
import Vue from 'vue';
import { Unit } from '@/types';
import units from '@/services/units';
import chartService from '@/services/chart';
import Chart from '../components/Chart.vue';

export default Vue.extend({
  name: 'Dashboard',
  components: {
    Chart,
  },
  data() {
    return {
      items: [] as Unit[],
      countAll: 0,
      countRunning: 0,
      countShutdown: 0,
      countAddWater: 0,
      countServiceRequired: 0,
      countInstalled: 0,
      chartData: {} as any,
      fields: [
        {
          label: 'Status',
          key: 'status',
          sortable: true,
        },
        {
          label: 'Count',
          key: 'count',
          sortable: true,
        },
      ],
      tableItems: [] as any[],
    };
  },
  methods: {
    async init(): Promise<void> {
      try {
        const u = await units.readAll();
        this.items.splice(0, this.items.length);
        u.forEach((o: Unit) => {
          this.items.push(o);
          this.countAll += 1;
          if (o.h2GenLastStatusCode === '00') {
            this.countRunning += 1;
          }
          if (o.h2GenLastStatusCode === '07' || o.h2GenLastStatusCode === '05') {
            this.countShutdown += 1;
          }
          if (o.h2GenLastStatusCode === '03' || o.h2GenLastStatusCode === '04' || o.h2GenLastStatusCode === '06') {
            this.countServiceRequired += 1;
          }
          if (o.h2GenLastStatusCode === '01') {
            this.countAddWater += 1;
          }
          if (o.h2GenLastStatusCode === '09') {
            this.countInstalled += 1;
          }
        });
        const title = 'Units Status';
        const labels = [] as string[];
        const data = [] as number[];
        const colours = [] as string[];
        this.tableItems.push({
          status: 'All Units',
          count: this.countAll,
          linkParameter: null,
        });
        if (this.countRunning > 0) {
          labels.push('Running');
          this.tableItems.push({
            status: 'Running',
            count: this.countRunning,
            linkParameter: 'running',
          });
          data.push(this.countRunning);
          colours.push('#42b23b');
        }
        if (this.countShutdown > 0) {
          labels.push('Shutdown');
          this.tableItems.push({
            status: 'Shutdown',
            count: this.countShutdown,
            linkParameter: 'shutdown',
          });
          data.push(this.countShutdown);
          colours.push('#cfcfdd');
        }
        if (this.countServiceRequired > 0) {
          labels.push('Service Required');
          this.tableItems.push({
            status: 'Service Required',
            count: this.countServiceRequired,
            linkParameter: 'service',
          });
          data.push(this.countServiceRequired);
          colours.push('#dd5555');
        }
        if (this.countAddWater > 0) {
          labels.push('Add Water');
          this.tableItems.push({
            status: 'Add Water',
            count: this.countAddWater,
            linkParameter: 'water',
          });
          data.push(this.countAddWater);
          colours.push('#254a96');
        }
        if (this.countInstalled > 0) {
          labels.push('Installed');
          this.tableItems.push({
            status: 'Installed',
            count: this.countInstalled,
            linkParameter: 'installed',
          });
          data.push(this.countInstalled);
          colours.push('#9966dd');
        }
        this.chartData = chartService.createPieChartData(title, labels, data, colours);
      } catch {
        // ignore for now...
      }
    },
  },
  async mounted(): Promise<void> {
    await this.init();
  },
});
