
import Vue from 'vue';
import { User, UserUnit } from '@/types';
import users from '@/services/users';
import organizations from '@/services/organizations';
import profile from '@/services/profile';

export default Vue.extend({
  name: 'Users',
  data() {
    return {
      isBusy: false,
      showAlert: true,
      fields: [
        {
          key: 'enabled',
          sortable: true,
        },
        {
          key: 'username',
          sortable: true,
        },
        {
          key: 'type',
          sortable: true,
        },
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'email',
          sortable: true,
        },
        {
          key: 'phone',
          sortable: true,
        },
        {
          key: 'organization',
          sortable: true,
        },
        // {
        //   key: 'date_created',
        //   label: 'Created',
        //   sortable: true,
        // },
        {
          key: 'notes',
          sortable: false,
        },
        {
          key: 'edit',
          sortable: false,
        },
      ],
      organizations: [] as any[],
      allItems: [] as User[],
      items: [] as User[],
      userSearch: '' as string,
      userDetailsEditMode: false,
      userTypeLocked: false,
      userDetailsModalVisible: false,
      userDetailsEntity: {} as User,
      allUserTypes: [] as any[],
      userTypes: [] as any[],
      units: [] as UserUnit[],
      unitsFields: [
        {
          key: 'tag',
          sortable: true,
        },
        {
          key: 'organization',
          sortable: true,
        },
        // {
        //   key: 'visible',
        //   sortable: true,
        // },
        {
          key: 'alerts',
          sortable: true,
        },
        // {
        //   key: 'repeat',
        //   sortable: true,
        // },
        // {
        //   key: 'clear',
        //   sortable: true,
        // },
      ],
      csv_data: [] as any[],
      csv_filename: 'empty.csv',
      allowedCreateUser: false,
      allowedEditUser: false,
    };
  },
  methods: {
    async init(): Promise<void> {
      try {
        const orgs = await organizations.readAll();
        orgs.forEach((o) => {
          this.organizations.push({
            value: o.id,
            text: o.name,
            type: o.type,
          });
        });
        const usrs = await users.readAll();
        this.allItems = usrs;
        this.items.splice(0, this.items.length);
        usrs.sort((e1, e2) => e1.username.localeCompare(e2.username))
          .forEach((u: User) => {
            this.items.push(u);
            if (u.customerId) {
              // eslint-disable-next-line no-param-reassign
              u.organization = this.organizations.find((o) => o.value === u.customerId);
            }
          });

        this.csv_data.splice(0, this.csv_data.length);
        if (this.items && this.items.length && this.items.length > 0) {
          this.items.forEach(i => {
            let orgName = null as string | null;
            if (i.customerId) {
              // eslint-disable-next-line no-param-reassign
              const org = this.organizations.find((o) => o.value === i.customerId);
              if (org) {
                orgName = org.text;
              }
            }
            const user = {
              enabled: i.enabled,
              username: i.username,
              type: this.getUserTypeName(i.userType),
              name: i.name,
              email: i.email,
              phone: i.phone,
              organization: orgName ? `${orgName}` : 'n/a',
            };
            this.csv_data.push(user);
          });

          this.csv_filename = `USERS_Export_${this.$moment()
            .format('YYYYMMDD_HHmm')}.csv`;
        }

        users.getUserTypeIds()
          .forEach((id) => {
            this.allUserTypes.push({
              value: id,
              text: users.getUserTypeName(id),
            });
          });

        const userType = profile.getUserType();
        if (userType) {
          if (userType > 50 || userType === 20) {
            this.allowedCreateUser = false;
          } else {
            this.allowedCreateUser = true;
          }

          if (userType === 10 || userType === 30) {
            this.allowedEditUser = true;
          }
        }
      } catch {
        // ignore for now...
      }
    },
    addUser(): void {
      this.units.splice(0, this.units.length);
      this.userDetailsEntity = {
        id: -1,
        enabled: true,
        username: '',
        passwordReset: '',
        randomPassword: false,
        userType: 10,
        name: '',
        email: '',
        phone: '',
        customerId: -1,
        organization: undefined,
        notes: '',
      };
      this.userTypeLocked = true;
      this.userDetailsEditMode = false;
      this.userDetailsModalVisible = true;
    },
    editUser(user: User): void {
      this.units.splice(0, this.units.length);

      const org = this.organizations.find((o) => o.value === user.customerId);
      this.setupUserTypeSelectionBasedOnOrg(org);

      this.userDetailsEntity = { ...user };

      this.userDetailsEditMode = true;
      this.userDetailsModalVisible = true;
    },
    setupUserTypeSelectionBasedOnOrg(org: any) {
      // manufacturer
      this.userTypes.splice(0, this.userTypes.length);
      if (org.type === 10) {
        this.userTypeLocked = false;
        this.userTypes.push({ value: 10, text: users.getUserTypeName(10) });
        this.userTypes.push({ value: 20, text: users.getUserTypeName(20) });
        this.userTypes.push({ value: 30, text: users.getUserTypeName(30) });
      } else if (org.type === 20) {
        this.userTypeLocked = true;
        this.userTypes.push({ value: 40, text: users.getUserTypeName(40) });
      } else if (org.type === 30) {
        this.userTypeLocked = true;
        this.userTypes.push({ value: 50, text: users.getUserTypeName(50) });
      } else if (org.type === 40) {
        this.userTypeLocked = false;
        this.userTypes.push({ value: 60, text: users.getUserTypeName(60) });
        this.userTypes.push({ value: 70, text: users.getUserTypeName(70) });
      }
    },
    userOrganizationChanged(): void {
      const org = this.organizations.find((o) => o.value === this.userDetailsEntity.customerId);
      this.setupUserTypeSelectionBasedOnOrg(org);
      this.userDetailsEntity.userType = this.userTypes[0].value;
    },
    async saveUser(): Promise<void> {
      const user = this.userDetailsEntity;
      if (user.customerId === -1) {
        this.$bvToast.toast('An organization must be selected', {
          title: 'Error',
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }

      // const filteredUnits = this.units.filter(unit => unit.visible === true);
      // if (filteredUnits.length > 1) {
      //   this.$bvToast.toast('You can only assign one unit to a driver, please try again', {
      //     title: 'Error',
      //     autoHideDelay: 5000,
      //     appendToast: false,
      //     variant: 'danger',
      //     solid: true,
      //   });
      //   return;
      // }
      if (user.passwordReset === '' || user.randomPassword) {
        user.passwordReset = null;
      }
      if (user.id >= 0) {
        await users.update(user.id, user);
        if (this.units.length > 0) {
          await users.setUnits(user.id, this.units);
        }
        const index = this.items.findIndex((o: User) => o.id === user.id);
        this.items.splice(index, 1, user);
      } else {
        const newUser = await users.create(user);
        this.items.push(newUser);
      }
      if (user.customerId) {
        user.organization = this.organizations.find((o) => o.value === user.customerId);
      }
    },
    async updateUnits(): Promise<void> {
      this.units = await users.getUnits(this.userDetailsEntity.id);
    },
    getUserTypeName(userType: number): string {
      return users.getUserTypeName(userType);
    },
    filterUsers(): void {
      if (this.userSearch) {
        const search = this.userSearch.toLowerCase();
        this.items.splice(0, this.items.length);
        this.allItems.sort((u1, u2) => u1.username.localeCompare(u2.username))
          .forEach((u: User) => {
            if (u.username && u.username.toLowerCase().includes(search)) {
              this.items.push(u);
            } else if (u.name && u.name.toLowerCase().includes(search)) {
              this.items.push(u);
            } else if (u.email && u.email.toLowerCase().includes(search)) {
              this.items.push(u);
            } else if (u.phone && u.phone.toLowerCase().includes(search)) {
              this.items.push(u);
            }
          });
      } else {
        this.items.splice(0, this.items.length);
        this.allItems.sort((u1, u2) => u1.username.localeCompare(u2.username))
          .forEach((u: User) => {
            this.items.push(u);
          });
      }
    },
  },
  async mounted(): Promise<void> {
    await this.init();
  },
});
