
import Vue from 'vue';
import auth from '@/services/auth';
import ProfileService from '@/services/profile';
import PreferencesService from '@/services/preferences';
import Sidebar from './views/Sidebar.vue';

export default Vue.extend({
  components: {
    Sidebar,
  },
  data() {
    return {
      profileModalVisible: false,
      changePasswordModalVisible: false,
      profile: {
        id: -1,
        name: '',
        email: '',
        phone: '',
      },
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      showHelpModal: false,
      userType: 99,
      units: 'metric',
      unitsOptions: [
        {
          value: 'metric',
          text: 'Metric',
        },
        {
          value: 'us',
          text: 'US',
        },
        {
          value: 'imperial',
          text: 'Imperial',
        },
      ],
    };
  },
  methods: {
    async signout(): Promise<void> {
      try {
        auth.logout();
        await this.$router.push('/login');
      } catch {
        // ignore for now...
      }
    },
    editProfile(): void {
      const profile = ProfileService.getStoredProfile();
      if (profile) {
        this.profile.id = profile?.id ?? -1;
        this.profile.name = profile?.name ?? '';
        this.profile.email = profile?.email ?? '';
        this.profile.phone = profile?.phone ?? '';
        if (this.profile.id !== -1) {
          this.profileModalVisible = true;
        }
      }
    },
    async saveProfile(): Promise<void> {
      try {
        if (this.profile.id !== -1) {
          await ProfileService.changeProfile(this.profile.id, this.profile);
          await ProfileService.updateStoredProfile();
          this.$bvToast.toast('Profile changed', {
            title: 'Success',
            autoHideDelay: 3000,
            appendToast: false,
            variant: 'success',
            solid: true,
          });
          PreferencesService.storeUnits(this.units);
        }
      } catch {
        this.$bvToast.toast('Profile not changed, please try again', {
          title: 'Error',
          autoHideDelay: 5000,
          appendToast: false,
          variant: 'danger',
          solid: true,
        });
      }
      this.profileModalVisible = false;
    },
    showChangePassword(): void {
      this.currentPassword = '';
      this.newPassword = '';
      this.confirmPassword = '';
      this.changePasswordModalVisible = true;
    },
    async changePassword(): Promise<void> {
      try {
        if (this.currentPassword && this.newPassword) {
          if (this.currentPassword !== '' && this.newPassword !== '') {
            if (this.newPassword === this.confirmPassword) {
              const changePasswordData = {
                oldPassword: this.currentPassword,
                newPassword: this.newPassword,
              };
              await ProfileService.changePassword(changePasswordData);
              this.$bvToast.toast('Password Changed', {
                title: 'Success',
                autoHideDelay: 5000,
                appendToast: false,
                variant: 'success',
                solid: true,
              });
            } else {
              this.$bvToast.toast('New password and confirmation do not match, please check', {
                title: 'Error',
                autoHideDelay: 5000,
                appendToast: false,
                variant: 'danger',
                solid: true,
              });
            }
          } else {
            this.$bvToast.toast('Passwords must not be empty', {
              title: 'Error',
              autoHideDelay: 5000,
              appendToast: false,
              variant: 'danger',
              solid: true,
            });
          }
        } else {
          this.$bvToast.toast('Passwords must not be empty', {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: false,
            variant: 'danger',
            solid: true,
          });
        }
        this.currentPassword = '';
        this.newPassword = '';
        this.confirmPassword = '';
      } catch (error) {
        if (error.request.status === 403) {
          this.$bvToast.toast('Your current password is incorrect', {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: false,
            variant: 'danger',
            solid: true,
          });
        } else {
          this.$bvToast.toast('An error occurred, please try again', {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: false,
            variant: 'danger',
            solid: true,
          });
        }
      }
      this.changePasswordModalVisible = false;
    },
    showHelp(): void {
      this.showHelpModal = true;
    },
    loadUserType(): void {
      const t = ProfileService.getUserType();
      if (t === null) {
        this.userType = 99;
      } else {
        this.userType = t;
      }
    },
    loadUserUnits(): void {
      this.units = PreferencesService.getUnits();
    },
  },
  mounted(): void {
    this.loadUserType();
    this.loadUserUnits();
  },
  watch: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    $route(to: string, from: string): void {
      this.loadUserType();
    },
  },
});
