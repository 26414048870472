import { User, UserUnit } from '@/types';
import apiService from './api';

const url = '/api/portal/users';

async function create(org: User): Promise<User> {
  const response = await apiService.post(url, org);
  return response.data;
}

async function read(id: number): Promise<User> {
  const response = await apiService.get(`${url}/${id}`);
  return response.data;
}

async function readAll(): Promise<User[]> {
  const response = await apiService.get(url);
  return response.data;
}

async function update(id: number, org: User): Promise<void> {
  await apiService.put(`${url}/${id}`, org);
}

async function deleteUser(id: number): Promise<void> {
  await apiService.delete(`${url}/${id}`);
}

async function getUnits(id: number): Promise<UserUnit[]> {
  const response = await apiService.get(`${url}/${id}/units`);
  return response.data;
}

async function setUnits(id: number, units: UserUnit[]): Promise<void> {
  await apiService.post(`${url}/${id}/units`, units);
}

function getUserTypeName(id: number): string {
  switch (id) {
    case 10:
      return 'admin';
    case 20:
      return 'installer';
    case 30:
      return 'manager';
    case 40:
      return 'primary dealer';
    case 50:
      return 'dealer';
    case 60:
      return 'customer';
    case 70:
      return 'driver';
    default:
      return 'unknown';
  }
}

function getUserTypeId(userType: string): number {
  switch (userType) {
    case 'admin':
      return 10;
    case 'installer':
      return 20;
    case 'manager':
      return 30;
    case 'primary dealer':
      return 40;
    case 'dealer':
      return 50;
    case 'customer':
      return 60;
    case 'driver':
      return 70;
    default:
      return 0;
  }
}

function getUserTypeIds(): number[] {
  return [10, 20, 30, 40, 50, 60, 70];
}

const usersService = {
  create,
  read,
  readAll,
  update,
  delete: deleteUser,
  getUnits,
  setUnits,
  getUserTypeName,
  getUserTypeId,
  getUserTypeIds,
};

export default usersService;
