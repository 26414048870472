
import Vue from 'vue';

export default Vue.extend({
  name: 'Sidebar',
  props: {
    userType: {
      type: Number,
      required: true,
    },
  },
  watch: {
    userType(): void {
      this.$forceUpdate();
    },
  },
});
