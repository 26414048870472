import {
  CustomerLists,
  DetailsLists,
  InstallationLists, InstallerData, PhotosList,
  Unit,
} from '@/types';
import apiService from './api';

const url = '/api/installers';

async function getUnitsForInstallation(): Promise<Unit[]> {
  const response = await apiService.get(`${url}/units`);
  return response.data;
}

async function getCustomers(): Promise<CustomerLists> {
  const response = await apiService.get(`${url}/customer`);
  return response.data;
}

async function getDetailsLists(): Promise<DetailsLists> {
  const response = await apiService.get(`${url}/details`);
  return response.data;
}

async function getInstallationLists(): Promise<InstallationLists> {
  const response = await apiService.get(`${url}/installation`);
  return response.data;
}

async function getInstallationData(tag: string): Promise<InstallerData> {
  const response = await apiService.get(`${url}/${tag}`);
  return response.data;
}

async function getPhotos(tag: string): Promise<PhotosList> {
  const response = await apiService.get(`${url}/${tag}/photos`);
  return response.data;
}

async function addPhoto(tag: string, photoId: string, photoBase64: string): Promise<void> {
  const data = {
    data: photoBase64,
  };
  await apiService.post(`${url}/${tag}/photos/${photoId}`, data);
}

async function deletePhoto(tag: string, photoId: string): Promise<void> {
  await apiService.delete(`${url}/${tag}/photos/${photoId}`);
}

async function saveInstallationData(tag: string, instalerData: InstallerData): Promise<void> {
  await apiService.post(`${url}/${tag}`, instalerData);
}

const installationService = {
  getUnitsForInstallation,
  getCustomers,
  getDetailsLists,
  getInstallationLists,
  getInstallationData,
  getPhotos,
  addPhoto,
  deletePhoto,
  saveInstallationData,
};

export default installationService;
