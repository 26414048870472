import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/globals.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueMoment from 'vue-moment';
import {
  LMap, LTileLayer, LTooltip, LMarker,
} from 'vue2-leaflet';
import { Icon } from 'leaflet';

import Fa from '@adambh/vue-fontawesome-autogen/Fa.vue';
// Import autogenerated fontawesome imports
// import './plugins/fontawesome-autogen';

import JsonCSV from 'vue-json-csv';

import 'leaflet/dist/leaflet.css';

import TokenService from '@/services/token';
import Api from '@/services/api';

import App from './App.vue';
import router from './router';
import store from './store';

Vue.use(BootstrapVue);
Vue.use(VueMoment);

library.add(far);
library.add(fas);
library.add(fal);
library.add(fad);

// Global register

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('fa', Fa);
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-tooltip', LTooltip);
Vue.component('downloadCsv', JsonCSV);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  // eslint-disable-next-line global-require
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  // eslint-disable-next-line global-require
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  // eslint-disable-next-line global-require
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

Vue.config.productionTip = false;

// Set the path suffix of the API
if (process.env.NODE_ENV === 'development') {
  // Api.init('https://test.hydralytica.com');
  // Api.init('https://api.dynacert.com');
  Api.init('http://localhost:5005');
  // Api.init('http://192.168.100.242:5005'); // Dynacert Local
}

// set existing token
if (TokenService.getToken()) {
  Api.setHeader();
  Api.mount401Interceptor();
}

Vue.filter('round', (value: any) => {
  if (typeof value !== 'number') {
    return value;
  }
  return value.toFixed(2);
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
