const chartService = {
  createBarChartData(title: string, chartLabels: string[], chartData: (number | null)[]): any {
    return {
      type: 'bar',
      data: {
        labels: chartLabels,
        datasets: [
          {
            label: title,
            data: chartData,
            backgroundColor: '#104c8f',
            borderColor: '#104c8f',
            borderWidth: 0,
          },
        ],
      },
      options: {
        skipNull: true,
        responsive: true,
        lineTension: 1,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                padding: 25,
              },
            },
          ],
        },
        plugins: {
          legend: {
            display: false,
            position: 'bottom',
          },
          title: {
            display: false,
            text: 'Chart.js Bar Chart',
          },
        },
      },
    };
  },
  createPieChartData(title: string,
    chartLabels: string[],
    chartData: number[],
    colours: string[]): any {
    return {
      type: 'pie',
      data: {
        labels: chartLabels,
        datasets: [
          {
            label: title,
            data: chartData,
            backgroundColor: colours,
            borderColor: '#104c8f',
            borderWidth: 0,
          },
        ],
      },
      options: {
        skipNull: true,
        responsive: true,
        lineTension: 1,
      },
    };
  },
};

export default chartService;
