import axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';
import router from '@/router';
import TokenService from './token';
import store from '../store';

const apiService = {

  interceptor401: 0,

  init(baseURL: string): void {
    axios.defaults.baseURL = baseURL;
  },

  setHeader(): void {
    axios.defaults.headers.common.Authorization = `Bearer ${TokenService.getToken()}`;
  },

  removeHeader(): void {
    axios.defaults.headers.common = {};
  },

  get<T = any, R = AxiosResponse<T>>(resource: string): Promise<R> {
    return axios.get(resource);
  },

  post<T = any, R = AxiosResponse<T>>(resource: string, data: T): Promise<R> {
    return axios.post(resource, data);
  },

  put<T = any, R = AxiosResponse<T>>(resource: string, data: T): Promise<R> {
    return axios.put(resource, data);
  },

  delete<T = any, R = AxiosResponse<T>>(resource: string): Promise<R> {
    return axios.delete(resource);
  },

  customRequest(req: AxiosRequestConfig) : AxiosPromise {
    return axios(req);
  },

  mount401Interceptor() : void {
    this.interceptor401 = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.request.status === 401) {
          if (error.config.url.includes('/auth') && error.config.method !== 'get') {
            // Refresh token has failed. Logout the user
            await store.dispatch('auth/clearToken');
            // throw error;
          } else {
            // Refresh the access token
            // try {
            //   await store.dispatch('auth/refreshToken');
            //   // Retry the original request
            //   const reqData : AxiosRequestConfig = {
            //     method: error.config.method,
            //     url: error.config.url,
            //     data: error.config.data,
            //   };
            //   return this.customRequest(reqData);
            // } catch (e) {
            //   // Refresh has failed - reject the original request
            //   throw error;
            // }
            await store.dispatch('auth/clearToken');
            window.location.replace(`${router.options.base}login`);
          }
        }

        // If error was not 401 just reject as is
        throw error;
      },
    );
  },

  unmount401Interceptor() : void {
    // Eject the interceptor
    axios.interceptors.response.eject(this.interceptor401);
  },
};

export default apiService;
