
import Vue from 'vue';
import auth from '@/services/auth';

export default Vue.extend({
  name: 'Login',
  data() {
    return {
      isBusy: false,
      username: '',
      password: '',
    };
  },
  methods: {
    async auth(): Promise<void> {
      try {
        await auth.login(this.username, this.password);
        await this.$router.push('/units');
      } catch {
        this.$bvToast.toast('Invalid credentials, please try again', {
          title: 'Error',
          autoHideDelay: 5000,
          appendToast: false,
          variant: 'danger',
          solid: true,
        });
      }
    },
  },
});
