
import Vue from 'vue';
import { Organization, OrganizationUnit } from '@/types';
import organizations from '@/services/organizations';
import profile from '@/services/profile';

export default Vue.extend({
  name: 'Organizations',
  data() {
    return {
      isBusy: false,
      showAlert: true,
      manufacturersFields: [
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'country',
          sortable: true,
        },
        {
          key: 'city',
          sortable: true,
        },
        {
          key: 'phone',
          sortable: true,
        },
        {
          key: 'notes',
          sortable: false,
        },
        {
          key: 'edit',
          sortable: false,
        },
      ],
      fields: [
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'country',
          sortable: true,
        },
        {
          key: 'city',
          sortable: true,
        },
        {
          key: 'phone',
          sortable: true,
        },
        {
          key: 'parentName',
          sortable: true,
        },
        {
          key: 'notes',
          sortable: false,
        },
        {
          key: 'edit',
          sortable: false,
        },
      ],
      organizationTypes: [] as any[],
      items: [] as Organization[],
      orgSearch: '',
      unitSearch: '' as string,
      manufacturers: [] as Organization[],
      distributors: [] as Organization[],
      dealers: [] as Organization[],
      customers: [] as Organization[],
      organizationEditMode: false,
      organizationDetailsModalVisible: false,
      organizationDetailsEntity: {} as Organization,
      availableParentOrganizations: [] as any[],
      units: [] as OrganizationUnit[],
      allUnits: [] as OrganizationUnit[],
      unitsFields: [
        {
          key: 'tag',
          sortable: true,
        },
        {
          key: 'organization',
          sortable: true,
        },
        {
          label: 'Assign',
          key: 'visible',
          sortable: true,
        },
      ],
      csv_data: [] as any[],
      csv_filename: 'empty.csv',
      allowedAddOrganization: false,
      allowedEditOrganization: false,
      extraUnit: '',
    };
  },
  methods: {
    async init(): Promise<void> {
      try {
        const orgs = await organizations.readAll();
        this.items.splice(0, this.items.length);
        this.manufacturers.splice(0, this.manufacturers.length);
        this.distributors.splice(0, this.distributors.length);
        this.dealers.splice(0, this.dealers.length);
        this.customers.splice(0, this.customers.length);
        orgs.sort((e1, e2) => e1.name.localeCompare(e2.name))
          .forEach((o: Organization) => {
            this.items.push(o);
            if (o.type > 10) {
              const parentOrg = orgs.find((x) => x.id === o.parentId);
              if (parentOrg) {
                // eslint-disable-next-line no-param-reassign
                o.parentName = parentOrg.name;
              }
            }
            if (this.organizationIsIncludedInFilter(o)) {
              switch (o.type) {
                case 10:
                  this.manufacturers.push(o);
                  break;
                case 20:
                  this.distributors.push(o);
                  break;
                case 30:
                  this.dealers.push(o);
                  break;
                case 40:
                  this.customers.push(o);
                  break;
                default:
                  break;
              }
            }
          });

        this.csv_data.splice(0, this.csv_data.length);
        if (this.items && this.items.length && this.items.length > 0) {
          this.items.forEach(i => {
            const org = {
              name: i.name,
              type: this.getOrganizationTypeName(i.type),
              country: i.country,
              city: i.city,
              phone: i.phone,
              parent: i.parentName,
            };
            this.csv_data.push(org);
          });

          this.csv_filename = `ORGANIZATIONS_Export_${this.$moment().format('YYYYMMDD_HHmm')}.csv`;
        }

        const userType = profile.getUserType();
        if (userType) {
          this.allowedEditOrganization = false;
          // if user is customer/driver or installer
          if (userType > 50 || userType === 20) {
            this.allowedAddOrganization = false;
          } else if (userType === 50) { // dealer
            this.allowedAddOrganization = true;
            this.organizationTypes.push({
              value: 40,
              text: organizations.getOrganizationTypeName(40),
            });
          } else if (userType === 40) { // distributor
            this.allowedAddOrganization = true;
            this.organizationTypes.push({
              value: 40,
              text: organizations.getOrganizationTypeName(40),
            });
            this.organizationTypes.push({
              value: 30,
              text: organizations.getOrganizationTypeName(30),
            });
          } else { // 10 or 30 (admin or manager)
            this.allowedAddOrganization = true;
            this.allowedEditOrganization = true;
            this.organizationTypes.push({
              value: 40,
              text: organizations.getOrganizationTypeName(40),
            });
            this.organizationTypes.push({
              value: 30,
              text: organizations.getOrganizationTypeName(30),
            });
            this.organizationTypes.push({
              value: 20,
              text: organizations.getOrganizationTypeName(20),
            });
            this.organizationTypes.push({
              value: 10,
              text: organizations.getOrganizationTypeName(10),
            });
          }
        }
      } catch {
        // ignore for now...
      }
    },
    addOrganization(): void {
      this.organizationDetailsEntity = {
        id: -1,
        name: '',
        code: '',
        parentId: null,
        parentName: '',
        country: '',
        city: '',
        phone: '',
        type: 40,
        notes: '',
      };
      this.organizationEditMode = false;
      this.organizationDetailsModalVisible = true;
      this.organizationTypeChanged();
    },
    editOrganization(org: Organization): void {
      this.organizationDetailsEntity = { ...org };
      this.organizationTypeChanged();
      this.organizationEditMode = true;
      this.organizationDetailsModalVisible = true;
    },
    async saveOrganization(): Promise<void> {
      const org = this.organizationDetailsEntity;
      if (org.id >= 0) {
        await organizations.update(org.id, org);
        if (this.units.length > 0) {
          if (this.extraUnit) {
            this.units.push({
              unitTag: this.extraUnit,
              organizationName: '',
              visible: true,
              extra: true,
            } as OrganizationUnit);
          }
          await organizations.setUnits(org.id, this.units);
        }
        await this.init();
      } else {
        const newOrg = await organizations.create(org);
        this.items.push(newOrg);
        if (this.organizationIsIncludedInFilter(newOrg)) {
          switch (newOrg.type) {
            case 10:
              this.manufacturers.push(newOrg);
              break;
            case 20:
              this.distributors.push(newOrg);
              break;
            case 30:
              this.dealers.push(newOrg);
              break;
            case 40:
              this.customers.push(newOrg);
              break;
            default:
              break;
          }
        }
      }
    },
    organizationIsIncludedInFilter(o: Organization): boolean {
      if (!this.orgSearch || this.orgSearch === '') {
        return true;
      }

      const search = this.orgSearch.toLowerCase();

      if (o.code && o.code.toLowerCase().includes(search)) {
        return true;
      }
      if (o.name && o.name.toLowerCase().includes(search)) {
        return true;
      }
      if (o.country && o.country.toLowerCase().includes(search)) {
        return true;
      }
      if (o.city && o.city.toLowerCase().includes(search)) {
        return true;
      }
      if (o.phone && o.phone.toLowerCase().includes(search)) {
        return true;
      }
      if (o.notes && o.notes.toLowerCase().includes(search)) {
        return true;
      }

      return false;
    },
    getOrganizationTypeName(orgType: number): string {
      return organizations.getOrganizationTypeName(orgType);
    },
    organizationTypeChanged(): void {
      const t = this.organizationDetailsEntity.type;
      console.log(`org type ${t}`);
      this.availableParentOrganizations.splice(0, this.availableParentOrganizations.length);

      this.items.forEach((o) => {
        console.log(`checking org ${o.name}`);
        if (organizations.canOrganizationBeParentOfType(t, o)) {
          console.log('CAN BE PARENT');
          this.availableParentOrganizations.push({
            value: o.id,
            text: o.name,
          });
        }
      });
    },
    async updateUnits(): Promise<void> {
      this.units = await organizations.getUnits(this.organizationDetailsEntity.id);
      this.allUnits = [...this.units];
      this.units.forEach(u => { u.visibleLocked = u.visible; });
    },
    filterOrganizations(): void {
      this.manufacturers.splice(0, this.manufacturers.length);
      this.distributors.splice(0, this.distributors.length);
      this.dealers.splice(0, this.dealers.length);
      this.customers.splice(0, this.customers.length);

      this.items.sort((e1, e2) => e1.name.localeCompare(e2.name))
        .forEach((o: Organization) => {
          if (this.organizationIsIncludedInFilter(o)) {
            switch (o.type) {
              case 10:
                this.manufacturers.push(o);
                break;
              case 20:
                this.distributors.push(o);
                break;
              case 30:
                this.dealers.push(o);
                break;
              case 40:
                this.customers.push(o);
                break;
              default:
                break;
            }
          }
        });
    },

    filterUnits(): void {
      this.units.splice(0, this.units.length);
      // console.log(`${this.units.length} - ${this.extraUnit} - ${JSON.stringify(this.units)}`);
      this.allUnits.sort((u1, u2) => u1.unitTag.localeCompare(u2.unitTag))
        .forEach((o: OrganizationUnit) => {
          if (this.unitIsIncludedInFilter(o)) {
            this.units.push(o);
          }
        });

      // eslint-disable-next-line max-len
      this.units = this.units.sort((a, b) => ((a.visible < b.visible) ? 1 : ((b.visible < a.visible) ? -1 : 0)));
    },

    unitIsIncludedInFilter(o: OrganizationUnit): boolean {
      if (!this.unitSearch || this.unitSearch === '') {
        return true;
      }

      const search = this.unitSearch.toLowerCase();

      if (o.unitTag && o.unitTag.toLowerCase().includes(search)) {
        return true;
      }
      return false;
    },
  },
  async mounted(): Promise<void> {
    await this.init();
  },
});
