import { TelematicsAccount } from '@/types';
import apiService from '@/services/api';

const url = '/api/portal/settings/telematics';

async function createTelematicsAccount(account: TelematicsAccount): Promise<TelematicsAccount> {
  const response = await apiService.post(`${url}`, account);
  return response.data;
}

async function readTelematicsAccounts(): Promise<TelematicsAccount[]> {
  const response = await apiService.get(`${url}`);
  return response.data;
}

async function updateTelematicsAccount(account: TelematicsAccount): Promise<void> {
  await apiService.put(`${url}/${account.instance}`, account);
}

async function deleteTelematicsAccount(instance: number): Promise<void> {
  await apiService.delete(`${url}/${instance}`);
}

const telematicsAccountsService = {
  createTelematicsAccount,
  readTelematicsAccounts,
  updateTelematicsAccount,
  deleteTelematicsAccount,
};

export default telematicsAccountsService;
