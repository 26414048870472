import { Organization, OrganizationUnit } from '@/types';
import apiService from './api';

const url = '/api/portal/organizations';

async function create(org: Organization): Promise<Organization> {
  const response = await apiService.post(url, org);
  return response.data;
}

async function read(id: number): Promise<Organization> {
  const response = await apiService.get(`${url}/${id}`);
  return response.data;
}

async function readAll(): Promise<Organization[]> {
  const response = await apiService.get(url);
  return response.data;
}

async function update(id: number, org: Organization): Promise<void> {
  await apiService.put(`${url}/${id}`, org);
}

async function deleteOrg(id: number): Promise<void> {
  await apiService.delete(`${url}/${id}`);
}

async function getUnits(id: number): Promise<OrganizationUnit[]> {
  const response = await apiService.get(`${url}/${id}/units`);
  return response.data;
}

async function setUnits(id: number, units: OrganizationUnit[]): Promise<void> {
  await apiService.post(`${url}/${id}/units`, units);
}

function getOrganizationTypeName(id: number): string {
  switch (id) {
    case 10:
      return 'Manufacturer';
    case 20:
      return 'Primary Dealer';
    case 30:
      return 'Dealer';
    case 40:
      return 'Customer';
    default:
      return 'Unknown';
  }
}

function getOrganizationTypeId(userType: string): number {
  switch (userType) {
    case 'Manufacturer':
      return 10;
    case 'Primary Dealer':
      return 20;
    case 'Dealer':
      return 30;
    case 'Customer':
      return 40;
    default:
      return 0;
  }
}

function getOrganizationTypeIds(): number[] {
  return [10, 20, 30, 40];
}

function canOrganizationBeParentOfType(t: number, org: Organization): boolean {
  switch (org.type) {
    case 10:
      return t === 20;
    case 20:
      return t === 30;
    case 30:
      return t === 40;
    default:
      return false;
  }
}

const organizationsService = {
  create,
  read,
  readAll,
  update,
  delete: deleteOrg,
  getOrganizationTypeName,
  getOrganizationTypeId,
  getOrganizationTypeIds,
  canOrganizationBeParentOfType,
  getUnits,
  setUnits,
};

export default organizationsService;
