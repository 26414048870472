const UNITS_KEY = 'units';

const PreferencesService = {
  getUnits(): string {
    const p = localStorage.getItem(UNITS_KEY);
    if (p) {
      return p;
    }
    return 'metric';
  },

  storeUnits(units: string): void {
    localStorage.setItem(UNITS_KEY, units);
  },

  removeUnits(): void {
    localStorage.removeItem(UNITS_KEY);
  },
};

export default PreferencesService;
