import { ChangePasswordData, User } from '@/types';
import ApiService from '@/services/api';

const PROFILE_KEY = 'profile';
const USERTYPE_KEY = 'usertype';

const url = '/api/me';

const ProfileService = {
  getStoredProfile(): User | null {
    const p = localStorage.getItem(PROFILE_KEY);
    if (p) {
      return JSON.parse(p);
    }
    return null;
  },

  getUserType(): number | null {
    const t = localStorage.getItem(USERTYPE_KEY);
    if (t) {
      return Number.parseInt(t, 10);
    }
    return null;
  },

  storeProfile(user: User): void {
    localStorage.setItem(PROFILE_KEY, JSON.stringify(user));
    localStorage.setItem(USERTYPE_KEY, user.userType.toString());
  },

  removeStoredProfile(): void {
    localStorage.removeItem(PROFILE_KEY);
    localStorage.removeItem(USERTYPE_KEY);
  },

  hasStoredProfile(): boolean {
    return localStorage.getItem(PROFILE_KEY) != null;
  },

  async updateStoredProfile(): Promise<void> {
    const response = await ApiService.get(url);
    this.storeProfile(response.data);
  },

  async changeProfile(profileId: number,
    profile: { phone: string; name: string; email: string }): Promise<void> {
    await ApiService.put(`${url}/${profileId}`, profile);
  },

  /**
   * Send a change password request
   */
  async changePassword(data: ChangePasswordData) : Promise<void> {
    await ApiService.put(`${url}/password`, data);
  },
};

export default ProfileService;
