
import Vue from 'vue';
import auth from '@/services/auth';

export default Vue.extend({
  name: 'Reset',
  data() {
    return {
      status: 'reset-request',
      isBusy: false,
      username: '',
      token: '',
      newPassword: '',
      newPasswordConfirmation: '',
    };
  },
  methods: {
    async reset(): Promise<void> {
      try {
        this.isBusy = true;
        const data = {
          username: this.username,
          password: null,
          resetToken: null,
        };
        await auth.reset(data);
        this.status = 'reset-requested';
      } catch {
        this.$bvToast.toast('An error occurred, please try again', {
          title: 'Error',
          autoHideDelay: 5000,
          appendToast: false,
          variant: 'danger',
          solid: true,
        });
      } finally {
        this.isBusy = false;
      }
    },
    async changePassword(): Promise<void> {
      if (this.newPassword === '' || this.newPassword !== this.newPasswordConfirmation) {
        this.$bvToast.toast('Invalid password', {
          title: 'Error',
          autoHideDelay: 5000,
          appendToast: false,
          variant: 'danger',
          solid: true,
        });
        return;
      }
      try {
        this.isBusy = true;
        const data = {
          username: null,
          password: this.newPassword,
          resetToken: this.token,
        };
        await auth.changePasswordWithResetToken(data);
        await this.$router.push('/login');
      } catch {
        this.$bvToast.toast('An error occurred, please try again', {
          title: 'Error',
          autoHideDelay: 5000,
          appendToast: false,
          variant: 'danger',
          solid: true,
        });
      } finally {
        this.isBusy = false;
      }
    },
  },
  mounted() {
    this.status = 'reset-request';
    if (this.$route.query.token) {
      this.status = 'reset-token';
      this.token = this.$route.query.token as string;
    }
  },
});
