
import Chart from 'chart.js/auto';
import { ChartItem } from 'chart.js';
// eslint-disable-next-line import/no-named-as-default
import Vue from 'vue';

export default Vue.extend({
  name: 'Chart',
  props: [
    'data',
  ],
  data() {
    return {
      chart: {} as any,
      firstRender: true,
    };
  },
  methods: {
    renderChart(): void {
      const ctx = this.$refs.chart;
      // eslint-disable-next-line no-new
      if (!this.firstRender) {
        (this.chart as any).destroy();
      }
      this.firstRender = false;
      this.chart = new Chart((ctx as ChartItem), this.data);
    },
  },
  watch: {
    data(): void {
      this.renderChart();
    },
  },
  mounted(): void {
    setTimeout(() => {
      this.renderChart();
    }, 1000);
  },
});
