
import Vue from 'vue';
import { TelematicsAccount } from '@/types';
import TelematicsService from '@/services/settings/telematics';

export default Vue.extend({
  name: 'Settings',
  data() {
    return {
      isBusy: false,
      fields: [
        {
          label: 'ID',
          key: 'instance',
          sortable: true,
        },
        {
          key: 'enabled',
          sortable: true,
        },
        {
          key: 'type',
          sortable: true,
        },
        {
          key: 'server',
          sortable: true,
        },
        {
          key: 'database',
          sortable: true,
        },
        {
          key: 'username',
          sortable: true,
        },
        {
          key: 'Info',
          sortable: false,
        },
        {
          key: 'Delete',
          sortable: false,
        },
      ],
      accountTypeOptions: [
        { value: 'geotab', text: 'Geotab' },
      ],
      enabled: true,
      allItems: [] as TelematicsAccount[],
      items: [] as TelematicsAccount[],
      account: null as TelematicsAccount | null,
      showTelematicsAccountModal: false,
    };
  },
  methods: {
    async init(): Promise<void> {
      try {
        this.isBusy = true;

        this.allItems = await TelematicsService.readTelematicsAccounts();
        this.items.splice(0, this.items.length);
        this.allItems.forEach(i => this.items.push(i));

        this.isBusy = false;
      } catch {
        // ignore for now
      }
    },
    async showTelematicsAccountDetails(account: TelematicsAccount) {
      this.account = { ...account };
      this.showTelematicsAccountModal = true;
    },
    async addTelematicsAccount() {
      this.account = {
        instance: -1,
        enabled: false,
        type: 'geotab',
        server: '',
        database: '',
        username: '',
        password: '',
      };
      this.showTelematicsAccountModal = true;
    },
    async createTelematicsAccount() {
      try {
        if (this.account === null) {
          return;
        }

        await TelematicsService.createTelematicsAccount(this.account);

        await this.init();
        this.showTelematicsAccountModal = false;
      } catch {
        // ignore for now
      }
    },
    async updateTelematicsAccount() {
      try {
        if (this.account === null) {
          return;
        }
        await TelematicsService.updateTelematicsAccount(this.account);

        await this.init();
        this.showTelematicsAccountModal = false;
      } catch {
        // ignore for now
      }
    },
    async deleteTelematicsAccount(account: TelematicsAccount) {
      try {
        const instance = account.instance;
        await TelematicsService.deleteTelematicsAccount(instance);

        await this.init();
      } catch {
        // ignore for now
      }
    },
  },
  async mounted(): Promise<void> {
    await this.init();
  },
});
