const TOKEN_KEY = 'token';

const TokenService = {
  getToken(): string | null {
    return localStorage.getItem(TOKEN_KEY);
  },

  saveToken(accessToken: string): void {
    localStorage.setItem(TOKEN_KEY, accessToken);
  },

  removeToken(): void {
    localStorage.removeItem(TOKEN_KEY);
  },

  hasAnyToken(): boolean {
    return localStorage.getItem(TOKEN_KEY) != null;
  },
};

export default TokenService;
