import {
  AnalyticsData, InstallerData,
  LocationsList, Pems,
  PhotosList, ServiceLog, ServiceLogReason, ServiceLogPhoto, SimActivationProfile,
  Unit, UnitBaseline,
} from '@/types';
import Analytics from '@/types/analytics';
import apiService from './api';

// import {
//   AnalyticsData, InstallerData,
//   LocationsList, Pems,
//   PhotosList, ServiceLog, ServiceLogReasons, ServiceLogPhoto, SimActivationProfile,
//   Unit, UnitBaseline,
// } from '@/types';
// import Analytics from '@/types/analytics';
// import apiService from './api';

const url = '/api/portal/units';

async function read(id: number): Promise<Unit> {
  const response = await apiService.get(`${url}/${id}`);
  return response.data;
}

async function readByTag(tag: string): Promise<Unit> {
  const response = await apiService.get(`${url}/${tag}`);
  return response.data;
}

async function readAll(all = false): Promise<Unit[]> {
  const response = await apiService.get(`${url}?all=${all}`);
  return response.data;
}

async function update(tag:string, installerData: InstallerData): Promise<void> {
  await apiService.put(`${url}/${tag}`, installerData);
}

async function analytics(tag: string, monthOffset: number): Promise<Analytics> {
  // JS month starts at 0, API month starts at 1
  const reqUrl = `${url}/${tag}/analytics?monthOffset=${monthOffset}`;
  const response = await apiService.get<AnalyticsData>(reqUrl);
  return new Analytics(response.data);
}

async function photos(tag: string): Promise<PhotosList> {
  const response = await apiService.get(`${url}/${tag}/photos`);
  return response.data;
}

async function changePhoto(tag: string, photoId: string, photo: string): Promise<void> {
  await apiService.post(`${url}/${tag}/photos/${photoId}`, photo);
}

async function deletePhoto(tag: string, photoId: string): Promise<void> {
  await apiService.delete(`${url}/${tag}/photos/${photoId}`);
}

async function locations(tag: string, startTimestamp: Date | null, stopTimestamp: Date | null)
  : Promise<LocationsList> {
  let reqUrl = `${url}/${tag}/locations`;
  if (startTimestamp !== null && stopTimestamp !== null) {
    reqUrl += `?startTimestamp=${startTimestamp.toISOString()}`;
    reqUrl += `&stopTimestamp=${stopTimestamp.toISOString()}`;
  }
  const response = await apiService.get<LocationsList>(reqUrl);
  return response.data;
}

async function createPems(tag: string, pems: Pems): Promise<Pems> {
  const response = await apiService.post(`${url}/${tag}/pems`, pems);
  return response.data;
}

async function readPems(tag: string): Promise<Pems[]> {
  const response = await apiService.get(`${url}/${tag}/pems`);
  return response.data;
}

async function updatePems(tag: string, pems: Pems): Promise<void> {
  await apiService.put(`${url}/${tag}/pems/${pems.id}`, pems);
}

async function deletePems(tag: string, id: number): Promise<void> {
  await apiService.delete(`${url}/${tag}/pems/${id}`);
}

async function createServiceLog(tag: string, sl: ServiceLog): Promise<ServiceLog> {
  const response = await apiService.post(`/api/engines/${tag}/service`, sl);
  return response.data;
}

async function readServiceLogs(tag: string): Promise<ServiceLog[]> {
  const response = await apiService.get(`/api/engines/${tag}/service`);
  return response.data;
}

async function readReasonsServiceLogs(tag: string): Promise<ServiceLogReason[]> {
  const reqUrl = `/api/engines/${tag}/service/reasons`;
  const response = await apiService.get(reqUrl);
  return response.data;
}

async function updateServiceLog(tag: string, sl: ServiceLog): Promise<void> {
  await apiService.put(`/api/engines/${tag}/service/${sl.id}`, sl);
}

async function deleteServiceLog(tag: string, id: number): Promise<void> {
  await apiService.delete(`/api/engines/${tag}/service/${id}`);
}

async function getServiceLogPhoto(tag: string, serviceLogId: number, photoId: string)
  : Promise<ServiceLogPhoto> {
  const reqUrl = `/api/engines/${tag}/service/${serviceLogId}/photos/${photoId}`;
  const response = await apiService.get(reqUrl);
  return response.data;
}

async function submitServiceLogPhoto(tag: string, serviceLogId: number, photo: string)
  : Promise<ServiceLogPhoto> {
  const p = {
    data: photo,
  } as ServiceLogPhoto;
  const reqUrl = `/api/engines/${tag}/service/${serviceLogId}/photos`;
  const response = await apiService.post(reqUrl, p);
  return response.data;
}

async function removeServiceLogPhoto(tag: string, serviceLogId: number, photoId: string)
  : Promise<void> {
  const reqUrl = `/api/engines/${tag}/service/${serviceLogId}/photos/${photoId}`;
  await apiService.delete(reqUrl);
}

async function readBaseline(tag: string): Promise<UnitBaseline> {
  const response = await apiService.get(`${url}/${tag}/baseline`);
  return response.data;
}

async function updateBaseline(tag: string, baseline: UnitBaseline): Promise<void> {
  await apiService.put(`${url}/${tag}/baseline`, baseline);
}

async function getSimActivationProfiles(): Promise<SimActivationProfile[]> {
  const response = await apiService.get(`${url}/sim/profiles`);
  return response.data;
}

const unitsService = {
  read,
  readByTag,
  readAll,
  update,
  analytics,
  photos,
  changePhoto,
  deletePhoto,
  locations,
  createPems,
  readPems,
  updatePems,
  deletePems,
  readBaseline,
  updateBaseline,
  createServiceLog,
  readServiceLogs,
  readReasonsServiceLogs,
  updateServiceLog,
  deleteServiceLog,
  getServiceLogPhoto,
  submitServiceLogPhoto,
  removeServiceLogPhoto,
  getSimActivationProfiles,
};

export default unitsService;
