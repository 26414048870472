import { AxiosRequestConfig } from 'axios';
import { ResetPasswordData } from '@/types';
import ProfileService from '@/services/profile';
import ApiService from './api';
import TokenService from './token';

const url = '/api/auth';

const AuthService = {
  /**
   * Login the user and store the access token to TokenService.
   *
   * @returns accessToken
   */
  async login(username: string, password: string) : Promise<string> {
    const requestData : AxiosRequestConfig = {
      method: 'POST',
      url,
      data: {
        username,
        password,
      },
    };

    try {
      const response = await ApiService.customRequest(requestData);

      TokenService.saveToken(response.data.token);
      ApiService.setHeader();
      ApiService.mount401Interceptor();

      await ProfileService.updateStoredProfile();

      return response.data.token;
    } catch (error) {
      throw error.response.data.detail;
    }
  },
  /**
   * Send a password reset request
   */
  async reset(data: ResetPasswordData) : Promise<void> {
    await ApiService.post(`${url}/reset`, data);
  },
  /**
   * Send a change password request
   */
  async changePasswordWithResetToken(data: ResetPasswordData) : Promise<void> {
    await ApiService.put(`${url}/reset`, data);
  },

  /**
   * Logout the current user by removing the token from storage.
   *
   * Will also remove `Authorization Bearer <token>` header from future requests.
   */
  logout() : void {
    TokenService.removeToken();
    ApiService.removeHeader();
    ProfileService.removeStoredProfile();

    ApiService.unmount401Interceptor();
  },
};

export default AuthService;
