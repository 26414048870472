import moment from 'moment';
import { AnalyticsData } from '@/types';
import chartService from '@/services/chart';

export default class Analytics {
  lifetimeOdometerKm: number;

  calcBasedOnHours: boolean

  baselineDate: Date;

  baselineFuelLiters: number;

  baselineTravelKm: number;

  baselineTravelRuntime: number;

  fuelEfficiencyBaseline: number;

  fuelEfficiencyOdometer: number;

  fuelEfficiencyRuntime: number;

  fuelEfficiencyThreeMonthsAgo: number;

  fuelEfficiencyTwoMonthsAgo: number;

  fuelEfficiencyOneMonthAgo: number;

  fuelSavingsLitersThreeMonthsAgo: number;

  fuelSavingsLitersTwoMonthsAgo: number;

  fuelSavingsLitersOneMonthAgo: number;

  fuelSavingsPercentageThreeMonthsAgo: number;

  fuelSavingsPercentageTwoMonthsAgo: number;

  fuelSavingsPercentageOneMonthAgo: number;

  measuredTravelKmOneMonthAgo: number;

  measuredTravelKmTwoMonthsAgo: number;

  measuredTravelKmThreeMonthsAgo: number;

  fuelSavingsLittersLastThreeMonths: number;

  fuelSavingsPercentageLastThreeMonths: number;

  savedFuelLiters: number;

  savedTonnesCO2e: number;

  fuelEfficiencyThreeMonthsAgoImperial: number;

  fuelEfficiencyTwoMonthsAgoImperial: number;

  fuelEfficiencyOneMonthAgoImperial: number;

  fuelEfficiencyRuntimeUS: number;

  fuelEfficiencyRuntimeImperial: number;

  fuelEfficiencyOdometerUS: number;

  fuelEfficiencyOdometerImperial: number;

  fuelEfficiencyBaselineUS: number;

  fuelEfficiencyBaselineImperial: number;

  fuelEfficiencyThreeMonthsAgoUS: number;

  fuelEfficiencyTwoMonthsAgoUS: number;

  fuelEfficiencyOneMonthAgoUS: number;

  fuelSavingsPercentLittersThreeMonthsAgo: number | null;

  fuelSavingsPercentUSThreeMonthsAgo: number | null;

  fuelSavingsPercentImperialThreeMonthsAgo: number | null;

  fuelSavingsPercentLittersTwoMonthsAgo: number | null;

  fuelSavingsPercentUSTwoMonthsAgo: number | null;

  fuelSavingsPercentImperialTwoMonthsAgo: number | null;

  fuelSavingsPercentLittersOneMonthAgo: number | null;

  fuelSavingsPercentUSOneMonthAgo: number | null;

  fuelSavingsPercentImperialOneMonthAgo: number | null;

  savedFuelPercentLittersLastThreeMonths: number;

  savedFuelPercentUSLastThreeMonths: number;

  savedFuelPercentImperialLastThreeMonths: number;

  savedFuelImperial: number;

  savedFuelUS: number;

  fuelSavingsThreeMonthsAgoColor: string;

  fuelSavingsTwoMonthsAgoColor: string;

  fuelSavingsOneMonthAgoColor: string;

  measuredTravelKmAverage: number;

  lifetimeOdometerMiles: number;

  baselineTravelMiles: number;

  baselineFuelImperial: number;

  baselineFuelUS: number;

  measuredTravelMilesAverage: number;

  totalSavingsPercentage: number;

  savedFuelLitersColor: string;

  savedFuelPercentLastThreeMonthsColor: string;

  greenColor = 'green';

  redColor = 'red';

  fuelEfficiencyChartData = {};

  fuelSavingsChartData = {};

  constructor(data: AnalyticsData) {
    this.lifetimeOdometerKm = data.lifetimeOdometerKm;
    this.calcBasedOnHours = data.calcBasedOnHours;
    this.baselineDate = data.baselineDate;
    this.baselineFuelLiters = data.baselineFuelLiters;
    this.baselineTravelKm = data.baselineTravelKm;
    this.baselineTravelRuntime = data.baselineRuntimeHours;
    this.fuelEfficiencyBaseline = data.fuelEfficiencyBaseline;
    this.fuelEfficiencyOdometer = data.fuelEfficiencyOdometer;
    this.fuelEfficiencyRuntime = data.fuelEfficiencyRuntime;
    this.fuelEfficiencyThreeMonthsAgo = this.RoundToTwo(data.fuelEfficiencyThreeMonthsAgo);
    this.fuelEfficiencyTwoMonthsAgo = this.RoundToTwo(data.fuelEfficiencyTwoMonthsAgo);
    this.fuelEfficiencyOneMonthAgo = this.RoundToTwo(data.fuelEfficiencyOneMonthAgo);
    this.fuelSavingsLitersThreeMonthsAgo = this.RoundToTwo(data.fuelSavingsLitersThreeMonthsAgo);
    this.fuelSavingsLitersTwoMonthsAgo = this.RoundToTwo(data.fuelSavingsLitersTwoMonthsAgo);
    this.fuelSavingsLitersOneMonthAgo = this.RoundToTwo(data.fuelSavingsLitersOneMonthAgo);
    // eslint-disable-next-line max-len
    this.fuelSavingsPercentageThreeMonthsAgo = this.RoundToTwo(data.fuelSavingsPercentageThreeMonthsAgo);
    // eslint-disable-next-line max-len
    this.fuelSavingsPercentageTwoMonthsAgo = this.RoundToTwo(data.fuelSavingsPercentageTwoMonthsAgo);
    this.fuelSavingsPercentageOneMonthAgo = this.RoundToTwo(data.fuelSavingsPercentageOneMonthAgo);
    this.measuredTravelKmOneMonthAgo = data.measuredTravelKmOneMonthAgo;
    this.measuredTravelKmTwoMonthsAgo = data.measuredTravelKmTwoMonthsAgo;
    this.measuredTravelKmThreeMonthsAgo = data.measuredTravelKmThreeMonthsAgo;
    this.fuelSavingsPercentageLastThreeMonths = data.fuelSavingsPercentageLastThreeMonths;
    this.fuelSavingsLittersLastThreeMonths = data.fuelSavingsLitersLastThreeMonths;
    this.savedFuelLiters = data.savedFuelLiters;
    this.totalSavingsPercentage = data.totalSavingsPercentage;
    this.savedTonnesCO2e = data.savedTonnesCO2e;
    this.fuelEfficiencyBaselineImperial = 282.481 / this.fuelEfficiencyBaseline;
    this.fuelEfficiencyThreeMonthsAgoImperial = this.fuelEfficiencyThreeMonthsAgo
      ? (282.481 / this.fuelEfficiencyThreeMonthsAgo)
      : 0;
    this.fuelEfficiencyTwoMonthsAgoImperial = this.fuelEfficiencyTwoMonthsAgo
      ? (282.481 / this.fuelEfficiencyTwoMonthsAgo)
      : 0;
    this.fuelEfficiencyOneMonthAgoImperial = 282.481 / this.fuelEfficiencyOneMonthAgo;
    this.fuelEfficiencyRuntimeUS = 235.215 / this.fuelEfficiencyRuntime;
    this.fuelEfficiencyRuntimeImperial = 282.481 / this.fuelEfficiencyRuntime;
    this.fuelEfficiencyOdometerUS = 235.215 / this.fuelEfficiencyOdometer;
    this.fuelEfficiencyOdometerImperial = 282.481 / this.fuelEfficiencyOdometer;
    this.fuelEfficiencyBaselineUS = 235.215 / this.fuelEfficiencyBaseline;
    this.fuelEfficiencyThreeMonthsAgoUS = this.fuelEfficiencyThreeMonthsAgo
      ? (235.215 / this.fuelEfficiencyThreeMonthsAgo)
      : 0;
    this.fuelEfficiencyTwoMonthsAgoUS = this.fuelEfficiencyTwoMonthsAgo
      ? (235.215 / this.fuelEfficiencyTwoMonthsAgo)
      : 0;
    this.fuelEfficiencyOneMonthAgoUS = 235.215 / this.fuelEfficiencyOneMonthAgo;
    this.fuelSavingsPercentLittersThreeMonthsAgo = this.fuelEfficiencyThreeMonthsAgo !== null
      ? (100 - ((this.fuelEfficiencyThreeMonthsAgo * 100) / this.fuelEfficiencyBaseline))
      : null;
    this.fuelSavingsPercentUSThreeMonthsAgo = this.fuelEfficiencyThreeMonthsAgo !== null
      ? ((this.fuelEfficiencyThreeMonthsAgoUS * 100) / this.fuelEfficiencyBaselineUS) - 100
      : null;
    this.fuelSavingsPercentImperialThreeMonthsAgo = this.fuelEfficiencyThreeMonthsAgo !== null
      // eslint-disable-next-line max-len
      ? ((this.fuelEfficiencyThreeMonthsAgoImperial * 100) / this.fuelEfficiencyBaselineImperial) - 100
      : null;
    this.fuelSavingsPercentLittersTwoMonthsAgo = this.fuelEfficiencyTwoMonthsAgo !== null
      ? (100 - ((this.fuelEfficiencyTwoMonthsAgo * 100) / this.fuelEfficiencyBaseline))
      : null;
    this.fuelSavingsPercentUSTwoMonthsAgo = this.fuelEfficiencyTwoMonthsAgo !== null
      ? ((this.fuelEfficiencyTwoMonthsAgoUS * 100) / this.fuelEfficiencyBaselineUS) - 100
      : null;
    this.fuelSavingsPercentImperialTwoMonthsAgo = this.fuelEfficiencyTwoMonthsAgo !== null
      // eslint-disable-next-line max-len
      ? ((this.fuelEfficiencyTwoMonthsAgoImperial * 100) / this.fuelEfficiencyBaselineImperial) - 100
      : null;
    this.fuelSavingsPercentLittersOneMonthAgo = this.fuelEfficiencyOneMonthAgo !== null
      ? (100 - ((this.fuelEfficiencyOneMonthAgo * 100) / this.fuelEfficiencyBaseline))
      : null;
    this.fuelSavingsPercentUSOneMonthAgo = this.fuelEfficiencyOneMonthAgo !== null
      ? ((this.fuelEfficiencyOneMonthAgoUS * 100) / this.fuelEfficiencyBaselineUS) - 100
      : null;
    this.fuelSavingsPercentImperialOneMonthAgo = this.fuelEfficiencyOneMonthAgo !== null
      // eslint-disable-next-line max-len
      ? ((this.fuelEfficiencyOneMonthAgoImperial * 100) / this.fuelEfficiencyBaselineImperial) - 100
      : null;
    // eslint-disable-next-line max-len
    this.savedFuelPercentLittersLastThreeMonths = this.CalcAverage(this.fuelSavingsPercentLittersThreeMonthsAgo, this.fuelSavingsPercentLittersTwoMonthsAgo, this.fuelSavingsPercentLittersOneMonthAgo);
    // eslint-disable-next-line max-len
    this.savedFuelPercentUSLastThreeMonths = this.CalcAverage(this.fuelSavingsPercentUSThreeMonthsAgo, this.fuelSavingsPercentUSTwoMonthsAgo, this.fuelSavingsPercentUSOneMonthAgo);
    // eslint-disable-next-line max-len
    this.savedFuelPercentImperialLastThreeMonths = this.CalcAverage(this.fuelSavingsPercentImperialThreeMonthsAgo, this.fuelSavingsPercentImperialTwoMonthsAgo, this.fuelSavingsPercentImperialOneMonthAgo);
    // this.savedFuelLiters %= 100;
    this.savedFuelImperial = (Math.round(this.savedFuelLiters * 0.219969) / 100);
    this.savedFuelUS = (Math.round(this.savedFuelLiters * 0.264172) / 100);
    // eslint-disable-next-line max-len
    this.fuelSavingsThreeMonthsAgoColor = this.fuelSavingsPercentLittersThreeMonthsAgo !== null && this.fuelSavingsPercentLittersThreeMonthsAgo > 0
      ? this.greenColor
      : this.redColor;
    // eslint-disable-next-line max-len
    this.fuelSavingsTwoMonthsAgoColor = this.fuelSavingsPercentLittersTwoMonthsAgo !== null && this.fuelSavingsPercentLittersTwoMonthsAgo > 0
      ? this.greenColor
      : this.redColor;
    // eslint-disable-next-line max-len
    this.fuelSavingsOneMonthAgoColor = this.fuelSavingsPercentLittersOneMonthAgo !== null && this.fuelSavingsPercentLittersOneMonthAgo > 0
      ? this.greenColor
      : this.redColor;
    this.measuredTravelKmAverage = this.measuredTravelKmThreeMonthsAgo
      // eslint-disable-next-line max-len
      ? (this.measuredTravelKmOneMonthAgo + this.measuredTravelKmTwoMonthsAgo + this.measuredTravelKmThreeMonthsAgo) / 3
      : this.measuredTravelKmTwoMonthsAgo
        ? (this.measuredTravelKmOneMonthAgo + this.measuredTravelKmTwoMonthsAgo) / 2
        : this.measuredTravelKmOneMonthAgo;
    this.lifetimeOdometerMiles = Math.round(this.lifetimeOdometerKm * 0.621371);
    this.baselineTravelMiles = Math.round(this.baselineTravelKm * 0.621371);
    this.baselineFuelImperial = Math.round(this.baselineFuelLiters * 0.219969);
    this.baselineFuelUS = Math.round(this.baselineFuelLiters * 0.264172);
    this.measuredTravelMilesAverage = Math.round(this.measuredTravelKmAverage * 0.621371);
    this.savedFuelLitersColor = this.savedFuelLiters > 0 ? this.greenColor : this.redColor;
    this.savedFuelPercentLastThreeMonthsColor = this.savedFuelPercentLittersLastThreeMonths > 0
      ? this.greenColor
      : this.redColor;
  }

  updateChartData(unitType: string, monthOffset: number): void {
    const lastMonths = this.getLastMonths(monthOffset);

    const threeMonthsAgo = lastMonths.threeMonthsAgo;
    const twoMonthsAgo = lastMonths.twoMonthsAgo;
    const oneMonthAgo = lastMonths.oneMonthAgo;
    const chartLabels = [threeMonthsAgo, twoMonthsAgo, oneMonthAgo];
    let eTitle: string;
    let sTitle: string;
    let eChartData: any[];
    let sChartData: any[];
    switch (unitType) {
      case 'imperial':
        eChartData = [
          this.fuelEfficiencyThreeMonthsAgoImperial,
          this.fuelEfficiencyTwoMonthsAgoImperial,
          this.fuelEfficiencyOneMonthAgoImperial,
        ];
        eTitle = this.calcBasedOnHours ? 'Fuel Efficiency (l/1h)' : 'Fuel Efficiency (mpg)';
        sTitle = 'Fuel Economy (%)';
        sChartData = [
          this.fuelSavingsPercentImperialThreeMonthsAgo,
          this.fuelSavingsPercentImperialTwoMonthsAgo,
          this.fuelSavingsPercentImperialOneMonthAgo,
        ];
        break;
      case 'us':
        eChartData = [
          this.fuelEfficiencyThreeMonthsAgoUS,
          this.fuelEfficiencyTwoMonthsAgoUS,
          this.fuelEfficiencyOneMonthAgoUS,
        ];
        eTitle = this.calcBasedOnHours ? 'Fuel Efficiency (l/1h)' : 'Fuel Efficiency (mpg)';
        sTitle = 'Fuel Economy (%)';
        sChartData = [
          this.fuelSavingsPercentUSThreeMonthsAgo,
          this.fuelSavingsPercentUSTwoMonthsAgo,
          this.fuelSavingsPercentUSOneMonthAgo,
        ];
        break;
      case 'metric':
      default:
        eChartData = [
          this.fuelEfficiencyThreeMonthsAgo,
          this.fuelEfficiencyTwoMonthsAgo,
          this.fuelEfficiencyOneMonthAgo,
        ];
        eTitle = this.calcBasedOnHours ? 'Fuel Efficiency (l/1h)' : 'Fuel Efficiency (l/100km)';
        sTitle = 'Fuel Economy (%)';
        sChartData = [
          this.fuelSavingsPercentageThreeMonthsAgo,
          this.fuelSavingsPercentageTwoMonthsAgo,
          this.fuelSavingsPercentageOneMonthAgo,
        ];
        break;
    }
    this.fuelEfficiencyChartData = chartService.createBarChartData(eTitle, chartLabels, eChartData);
    this.fuelSavingsChartData = chartService.createBarChartData(sTitle, chartLabels, sChartData);
  }

  // eslint-disable-next-line class-methods-use-this
  getLastMonths(monthOffset: number): Record<string, string> {
    const threeMonthsAgo = moment()
      .subtract(3 + (monthOffset * -1), 'months')
      .format('MMM');
    const twoMonthsAgo = moment()
      .subtract(2 + (monthOffset * -1), 'months')
      .format('MMM');
    const oneMonthAgo = moment()
      .subtract(1 + (monthOffset * -1), 'months')
      .format('MMM');

    return { threeMonthsAgo, twoMonthsAgo, oneMonthAgo };
  }

  // eslint-disable-next-line class-methods-use-this
  getLastMonthsHasBaselineDate(baselineDate: Date, monthOffset: number): boolean {
    const baselineMoment = moment(baselineDate);
    const threeMonthsAgo = moment().subtract(3 + (monthOffset * -1), 'months');
    const oneMonthAgo = moment().subtract(1 + (monthOffset * -1), 'months');

    return baselineMoment.isBetween(threeMonthsAgo, oneMonthAgo, 'month', '[]');
  }

  // eslint-disable-next-line class-methods-use-this
  CalcAverage(val1: number | null, val2: number | null, val3: number | null): number {
    let count = 0;
    let sum = 0.0;
    if (val1 !== null) {
      count += 1;
      sum += val1;
    }
    if (val2 !== null) {
      count += 1;
      sum += val2;
    }
    if (val3 !== null) {
      count += 1;
      sum += val3;
    }

    return sum / count;
  }

  // eslint-disable-next-line class-methods-use-this
  RoundToTwo(num:number) : number {
    if (num != null) {
      return parseFloat(num.toFixed(2));
    }
    return 0;
  }
}
