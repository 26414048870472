import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import ProfileService from '@/services/profile';
import AuthService from '@/services/auth';
import TokenService from '@/services/token';
import Dashboard from '../views/Dashboard.vue';
import Organizations from '../views/Organizations.vue';
import Users from '../views/Users.vue';
import Units from '../views/Units.vue';
import Settings from '../views/Settings.vue';
import Login from '../views/Login.vue';
import Reset from '../views/Reset.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/organizations',
    name: 'organizations',
    component: Organizations,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/units',
    name: 'units',
    component: Units,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      guest: true,
    },
  },
  {
    path: '/reset',
    name: 'reset',
    component: Reset,
    meta: {
      guest: true,
    },
  },
  {
    path: '*',
    redirect: '/dashboard',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (TokenService.hasAnyToken() && ProfileService.hasStoredProfile()) {
      next();
    } else {
      AuthService.logout();
      window.location.replace(`${router.options.base}login`);
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (TokenService.hasAnyToken() && ProfileService.hasStoredProfile()) {
      next({ path: '/dashboard' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
