import Vue from 'vue';
// import router from '../../router';
import storage from '../../services/token';

interface AuthState {
  token: string
}

const state: AuthState = {
  token: '',
};

const getters = {};

const actions = {
  async clearToken(): Promise<void> {
    storage.removeToken();
    // await router.push('/login');
  },
};

function setToken(authState: AuthState, token: string): void {
  Vue.set(authState, 'token', token);
}
function clearToken(authState: AuthState): void {
  Vue.set(authState, 'token', '');
}

const mutations = {
  setToken,
  clearToken,
};

const auth = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default auth;
